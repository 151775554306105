

























































































































































































import { Mixins, Component, Watch } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, Bit, HiveGameInfo, HiveStatus, PagedResults, SchoolModel, TableState, UserStub } from '@/edshed-common/api'
import io from 'socket.io-client'
import moment from 'moment'
import { debounce } from 'lodash'
import RoundIndicator from './components/Hive/RoundIndicator.vue'
import PlayersTable from './components/Hive/PlayersTable.vue'

@Component({
  name: 'Hives',
  components: {
    RoundIndicator,
    PlayersTable
  }
})
export default class Hives extends Mixins(ComponentHelper) {
  HiveStatus = HiveStatus
  hiveData: PagedResults<HiveGameInfo> = {
    items: [],
    total: 0
  }

  roomStatus: { room: number, status: string }[] = []

  roomData: { room: number, round: number, roundData: null | any[], leagueData: any[] }[] = []

  roomMembers: { room: number, members: {}[] }[] = []

  roomResults: {
    room: number,
    data: {
      answer_given: string
      correct: Bit
      display_name: string
      hive_id: number
      name: string
      round: number
      score: number
      user_id: number
      username: string
      word: string
    }[]
  }[] = []

  tableState: TableState = {
    perPage: 10,
    dir: 'asc',
    page: 1,
    sort: '',
    term: ''
  }

  loading: boolean = false

  isConnected: boolean = false

  socket: SocketIOClient.Socket | null = null

  schoolSearchTerm: string = ''

  userSearchTerm: string = ''

  schoolSearchResults: SchoolModel[] = []

  userSearchResults: UserStub[] = []

  isFetching: boolean = false

  hasSearched: boolean = false

  selectedSchool: SchoolModel | null = null

  selectedUser: UserStub | null = null

  userSearchPage: number = 0

  totalUserSearchResults: number = 0

  filterStatuses: HiveStatus[] = ['created', 'started']

  roomFilter: string = ''

  idFilter: string = ''

  @Watch('schoolSearchTerm')
  onSchoolSearchChanged (val: string) {
    if (val === '') {
      this.schoolSearchResults = []
      this.resetUserSearch()
      this.hasSearched = false
    }
  }

  @Watch('selectedSchool')
  onSelectedSchoolChanged (val: SchoolModel | null) {
    if (val) {
      this.resetUserSearch()
      this.searchUsers(this.userSearchTerm)
    } else {
      this.schoolSearchResults = []
      this.resetUserSearch()
      this.hasSearched = false
    }
  }

  mounted () {
    this.startSocket()
    this.getHivesData()
    this.joinRooms()
  }

  resetUserSearch () {
    this.userSearchResults = []
    this.userSearchPage = 0
  }

  joinRooms () {
    for (const hive of this.hiveData.items) {
      if (hive.status === 'started' || hive.status === 'created') {
        this.socket?.emit('join-room', hive.room)
      }
    }
  }

  leaveRooms () {
    for (const hive of this.hiveData.items) {
      this.socket?.emit('leave-room', hive.room)
    }
  }

  getRoundForRoom (room: number) {
    return this.roomData.find(r => r.room === room)?.round ?? 0
  }

  getAnswersGivenForRoom (room: number) {
    return this.roomData.find(r => r.room === room)?.roundData?.length ?? 0
  }

  getAnswersRequiredForRoom (room: number) {
    return this.roomMembers.find(r => r.room === room)?.members.length ?? 0
  }

  getAllResultsForRoom (room: number) {
    return this.roomResults.find(r => r.room === room)?.data ?? []
  }

  getMembersForRoom (room: number) {
    return this.roomMembers.find(r => r.room === room)?.members ?? []
  }

  getPlayStateForRoom (room: number) {
    const state = this.roomStatus.find(r => r.room === room)?.status

    switch (state) {
      case 'playing':
        return 'Answering question'
      case 'league':
        return 'Viewing league'
      case 'started':
        return 'Waiting for players'
      case 'results':
        return 'Viewing results'
      default:
        return state
    }
  }

  isRoomExpired (room: HiveGameInfo) {
    return moment(room.expiry).isBefore()
  }

  isRoomAwaitingInitialPlayers (room: number) {
    const status = this.roomStatus.find(r => r.room === room)?.status
    const round = this.roomData.find(r => r.room === room)?.round

    if (status === undefined || round === undefined) {
      return false
    }

    return status === 'started' && round === 0
  }

  hivePageChanged (page: number) {
    this.tableState.page = page

    this.getHivesData()
  }

  startSocket () {
    const secure = this.config.serverURI.indexOf('https') === 0

    this.socket = io(this.config.serverURI, { secure, query: `token=${this.$store.state.token}&hive_role=observer` })

    const socket = this.socket

    const that = this

    socket.on('connect', function () {
      that.isConnected = true
    })
    socket.on('disconnect', function () {
      that.isConnected = false
    })
    socket.on('reconnect', function () {
      that.joinRooms()
    })
    socket.on('room-status', function (msg) {
      const record = that.roomStatus.findIndex(r => r.room === parseInt(msg.room))

      that.roomStatus.splice(record === -1 ? 0 : record, record === -1 ? 0 : 1, { room: parseInt(msg.room), status: msg.status })
    })

    socket.on('room-members', function (msg) {
      const record = that.roomMembers.findIndex(r => r.room === parseInt(msg.room))

      that.roomMembers.splice(record === -1 ? 0 : record, record === -1 ? 0 : 1, { room: parseInt(msg.room), members: msg.members })
    })

    socket.on('rounds-data', function (msg) {
      const record = that.roomResults.findIndex(r => r.room === parseInt(msg.room))

      that.roomResults.splice(record === -1 ? 0 : record, record === -1 ? 0 : 1, { room: parseInt(msg.room), data: msg.data ?? [] })
    })

    socket.on('error', function (msg) {
      // if (msg === 'Authentication error') {
      //   that.closeModal()
      // }
      if (process.env.NODE_ENV === 'development') {
        alert(msg)
      }
    })
    socket.on('round-data', function (msg) {
      const record = that.roomData.findIndex(r => r.room === parseInt(msg.room))

      const roundsData = that.roomResults.findIndex(r => r.room === parseInt(msg.room))

      if (roundsData === -1) {
        that.roomResults.push({ room: parseInt(msg.room), data: msg.roundData ?? [] })
      } else {
        that.roomResults[roundsData].data = that.roomResults[roundsData].data.filter(d => d.round !== parseInt(msg.round))
        that.roomResults[roundsData].data.push(...(msg.roundData ?? []))
      }

      // console.log({ roundsData })
      // const roundIndex = roundsData?.data.findIndex(r => r.round === msg.round)
      // console.log({ roundIndex })

      // if (!roundsData) {
      //   console.log('pushing room result')
      //   that.roomResults.push({ room: msg.room, data: [msg.roundData] })
      // } else {
      //   if (roundIndex === undefined || roundIndex === -1) {
      //     console.log('pushing round data')
      //     roundsData.data.push(msg.roundData)
      //   } else {
      //     console.log('splicing round data')
      //     roundsData.data.splice(roundIndex, 1, msg.roundData)
      //   }
      // }

      that.roomData.splice(record === -1 ? 0 : record, record === -1 ? 0 : 1, { room: parseInt(msg.room), round: msg.round, roundData: msg.roundData, leagueData: msg.leagueData })
    })
    socket.on('show-question', function (msg) {
      const record = that.roomData.find(r => r.room === parseInt(msg.room))

      if (record) {
        record.roundData = []
        record.round = msg.question
      }

      const state = that.roomStatus.find(r => r.room === parseInt(msg.room))

      if (state) {
        state.status = 'playing'
      }
    })
    socket.on('end-game', function (msg) {
      const record = that.hiveData.items.find(r => r.room === parseInt(msg.room))

      if (record) {
        record.status = 'finished'
        record.room = null! // can't be null since we've just looked it up to room id
      }

      const state = that.roomStatus.find(r => r.room === parseInt(msg.room))

      if (state) {
        state.status = 'playing'
      }
    })
  }

  async getHivesData () {
    this.loading = true

    try {
      this.leaveRooms()
      this.hiveData = await Api.useNewQueryFormat().getHiveGames({ school_id: this.selectedSchool?.id, owner_id: this.selectedUser?.id, status: this.filterStatuses.length === 0 ? undefined : this.filterStatuses, id: this.idFilter === '' ? undefined : parseInt(this.idFilter), room: this.roomFilter === '' ? undefined : parseInt(this.roomFilter), creator_type: { pupil: true, teacher: true } }, { dir: 'desc', sort: 'created', take: this.tableState.perPage, skip: (this.tableState.page - 1) * this.tableState.perPage })
      this.joinRooms()
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not load hive game data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  searchSchoolsDebounced = debounce((school) => {
    this.searchSchools(school)
  }, 500)

  async searchSchools (school: string) {
    if (school.length < 3) {
      this.schoolSearchResults = []
      return
    }

    this.isFetching = true

    if (!this.$store.state.user?.superuser) {
      return
    }

    try {
      this.schoolSearchResults = await Api.searchSchools<true>(school, false)
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Error searching schools',
        type: 'is-danger'
      })

      console.log(err)
    } finally {
      this.isFetching = false
    }
  }

  searchUsersDebounced = debounce((user) => {
    this.resetUserSearch()
    this.searchUsers(user)
  }, 500)

  async searchUsers (name: string) {
    if (name.length < 3 && this.selectedSchool === null) {
      this.userSearchResults = []
      this.userSearchPage = 0
      this.hasSearched = false
      return
    }

    this.isFetching = true

    try {
      const results = await Api.userSearch({ username: name, school_id: this.selectedSchool?.id }, { take: 10, skip: 10 * this.userSearchPage })
      this.userSearchResults.push(...results.items)
      this.totalUserSearchResults = results.total
      this.hasSearched = true
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Error searching users',
        type: 'is-danger'
      })

      console.log(err)
    } finally {
      this.isFetching = false
    }
  }

  async searchMoreUsers () {
    if (this.userSearchResults.length === this.totalUserSearchResults) {
      return
    }

    this.userSearchPage++

    await this.searchUsers(this.userSearchTerm)
  }
}

