









import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'RoundIndicator'
})
export default class RoundIndicator extends Mixins(ComponentHelper) {
  @Prop({ required: true }) roundCount!: number

  @Prop({ required: true }) currentRound!: number

  maxDisplayedRounds: number = 15

  get blocks () {
    const blocks: { round: number, status: 'played' | 'current' | 'future'}[] = []
    for (let i = 0; i < this.roundCount; i++) {
      blocks.push({ round: i, status: i === this.currentRound ? 'current' : i < this.currentRound ? 'played' : 'future' })
    }
    return blocks
  }
}

