
















































import { Bit, HiveGameInfo, HiveRole, UserModel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'PlayersTable'
})
export default class PlayersTable extends Mixins(ComponentHelper) {
  @Prop({ required: true }) results!: {
    answer_given: string
    correct: Bit
    display_name: string
    hive_id: number
    name: string
    round: number
    score: number
    user_id: number
    username: string
    word: string
  }[]

  @Prop({ required: true }) hiveInfo!: HiveGameInfo

  @Prop({ required: true }) members!: (UserModel & { hive_role: HiveRole, user_agent: string })[]

  @Prop({ required: true }) round!: number

  getIconForUserAgent (userAgent?: string) {
    if (userAgent === undefined) {
      return 'lan-disconnect'
    }

    if (userAgent.includes('Android') || userAgent.includes('okhttp')) {
      return 'android'
    } else if (userAgent.includes('ios')) {
      return 'apple-ios'
    } else {
      return 'laptop'
    }
  }

  get players () {
    const res = this.results
    const map = new Map<number, typeof res[number]>()

    for (const row of this.results) {
      map.set(row.user_id, row)
    }

    return Array.from(map.values()).map((r) => {
      const member = this.members.find(m => m.id === r.user_id)

      return {
        username: r.username,
        id: r.user_id,
        name: r.name,
        display_name: r.display_name,
        user_agent: member?.user_agent,
        is_live: member !== undefined
      }
    })
  }
}

